import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Юридические услуги Казань | Юридическая компания | Алмаз" description="! 【Качественные Юридические услуги】 ⚖️ «Алмаз» ➥ ☝Любой Сложности ✔️Комплексное решение ✔️Законно ✍Грамотно ⭐ По Казани" />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/contact'
				name="Юридические услуги Казань | Юридическая компания | Алмаз"
				description="! 【Качественные Юридические услуги】 ⚖️ «Алмаз» ➥ ☝Любой Сложности ✔️Комплексное решение ✔️Законно ✍Грамотно ⭐ По Казани"
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Юридические услуги" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Юридические услуги в Казани"}
			
				html={

				`
				<p class="TextText">
                	<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Компания Алмаз стремится предоставлять высококачественные юридические услуги. Наша компания ставит интересы клиентов на первое место и прилагает все усилия, чтобы юридические услуги были максимально эффективными и оказаны в кратчайшие сроки. Мы понимаем важность услуг нашей компании для лучшего удовлетворения их потребностей, поскольку от этого часто зависят действия наших клиентов. Наше отличие от других юридических фирм - это индивидуальный подход к каждому клиенту и предоставление консультаций опытным юристом с большим стажем госслужбы. Мы всегда нацелены на своевременное выполнение взятых на себя обязательств и стараемся максимально сократить сроки выполнения наших услуг.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Юридические услуги нашей компании включают в себя очень широкий спектр услуг, которые могут быть очень выгодными для наших клиентов, потому что благодаря этому вам не нужно постоянно искать компанию, которая будет сопровождать вас в возникающих юридических вопросах. Благодаря компетентному совету наших юристов вы можете получить не только необходимую поддержку, но и знания, которые могут понадобиться для вашей будущей деятельности. Постоянный анализ рынка юридических услуг позволяет нам предлагать нашим клиентам самые выгодные цены. При формировании стоимости наших услуг мы учитываем все нюансы, чтобы с нашей помощью заказчик не ожидал непредвиденных расходов в виде дополнительных платежей и комиссий. Наша компания дорожит своей репутацией, поэтому мы всегда стараемся предоставлять услуги на таком уровне, чтобы клиенты хотели обращаться к нам в будущем. Мы не экономим на информации и всегда стараемся дать исчерпывающую консультацию нашим клиентам, даже в тех случаях, когда они еще не воспользовались юридическими услугами нашей компании.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Хорошие юристы нужны не только большим компаниям, но и физическим лицам - обычным гражданам и предпринимателям. Каждый день россияне сталкиваются с рядом проблем, которые можно легко решить. Правовые вопросы развода, наследования, а также проблемы с оформлением и составлением документов, сделки с недвижимостью. Если вы не можете самостоятельно выполнить поставленные задачи, специалисты «Алмаза» будут рады вам помочь. У нас нет скрытых дополнительных затрат, мы можем сразу рассчитать окончательную стоимость работ. Мы предлагаем нашим клиентам телефонные консультации, где вы можете узнать полную информацию по всем ключевым моментам. Свяжитесь с нашими юристами и узнайте обо всех преимуществах работы с нами.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Услуги нашей компании может позволить себе каждый человек, независимо от уровня своих доходов. Это значит, что для всех слоев населения Казани компания предоставляет пакет услуг, гарантирующий абонентское обслуживание на выгодных условиях. Данная услуга позволяет значительно сэкономить на цене услуг, а также сокращает время на поиски юридической конторы, которая могла бы решить возникшие проблемы. В этот пакет входят услуги по составлению юридической документации, различное консультирование, учет договоров, участие в судебных разбирательствах и арбитраже.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Люди, обращающиеся в нашу организацию, осознают, что качественное юридическое обслуживание необходимо для благополучного и успешного развития бизнеса. Поэтому они обращаются к нам в случаях возникновения конфликтных ситуаций с другими компаниями в поисках компромиссного решения.
				</p>

				<p class="TextH"><b>Преимущества консультации у юриста</b></p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Консультации у квалифицированного юриста имеют ряд неоспоримых преимуществ. Прежде всего, вы обращаетесь непосредственно к профессионалу, который имеет соответствующее образование, подготовку и опыт. Только грамотный юрист способен полностью вникнуть в суть вопроса, разобраться в его нюанса, взвесить все «за» и «против». Он сможет просчитать наперед возможные последствия, разложит по полочкам все негативные и позитивные стороны сделки, поможет подготовить юридические бумаги и даже стать вашим законным представителем в суде или органах государственной власти. Таким образом, вы будете застрахованы от всевозможных рисков и нелепых ошибок, ведь грамотный специалист всегда подходит к решению вашего конкретного вопроса сугубо индивидуально. Юридическая консультация будет иметь профессиональное и в тоже время максимально понятное толкование и разъяснение действующего законодательства, исходя из проблематики интересующего вопроса.
				</p>

				<p class="TextH"><b>Правильные юридические услуги как гарантия качества</b></p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Наверное, каждому хотя бы раз в жизни приходится сталкиваться с различными юридическими вопросами, разобраться в которых без помощи опытных специалистов задача практически непосильная. Юридические услуги высококвалифицированного опытного специалиста помогут не только вам сэкономить уйму времени и нервов (ведь всю рутинную работу юрист берет на себя), но и являются гарантией того, что вы не допустите досадных ошибок. Только человек со специальным юридическим образованием и опытом сможет грамотно и правильно проанализировать сложившуюся ситуацию на основе действующего законодательства, разберется во всех нюансах и предложит максимально выгодный для вас путь решения проблемы. Вот почему в таких специфических и щепетильных вопросах самый лучший выход – обратиться за помощью к юристам. Помните, что юридические услуги заключаются не только в представлении интересов в суде, но и в консультациях, а также юридическом сопровождении.
				</p>

				<p class="TextH"><b>Наши юридические услуги Вам обязательно помогут!</b></p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Продвижение бизнеса, заключения контрактов и сделок, получение наследства… В современном мире вряд ли можно обойтись без помощи опытного юриста. Только опытные профессионалы могут провести для вас грамотную консультацию, помочь в решении проблем. Нужно полное сопровождение хозяйственной деятельности вашего предприятия? Нет проблем! Потребовалась помощь в решении спорных ситуаций? Обращайтесь за помощью к нам! Хотите иметь своего законного представителя в суде, правоохранительных органах или органах государственной власти и местного самоуправления? Решения всех необходимых вопросов и проблем мы возьмем на себя! В сегодняшних условиях часто меняющегося законодательства и нестабильной экономической ситуации без высококвалифицированной юридической консультации никак не обойтись! Мы поможем вам сэкономить ваши деньги и силы. Доверьтесь профессионалам и вы получите юридическую помощь и максимально качественный результат!
				</p>

				<p class="TextH"><b>Юридические консультации залог вашего благополучия!</b></p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Чтобы постоянно идти в ногу со временем, застраховать свой бизнес от подводных камней (например, заключения невыгодных сделок) или быстро и эффективно улаживать различные юридические вопросы в строгом соответствии с буквой закона, вам обязательно понадобится профессиональный и опытный юрист. Только квалифицированный специалист сможет дать исчерпывающую и полную консультацию доступным и простым языком, не выходя за пределы правового поля. Разобраться во всех тонкостях и нюансах национального законодательства обычному человеку зачастую не под силу. Процесс это сложный и трудоемкий, требующий много времени и нервов, ведь только законодательных и подзаконных актов существует великое множество. Да и личная инициатива может быть легко наказуема – наломать дров может каждый, а допустить ошибку на юридическом поприще проще простого. Поэтому доверьте решения ваших юридических вопросов опытным юристам, которые профессионально и быстро смогут вникнуть в суть проблемы и найти лучшие варианты ее решения с максимальной пользой для вас и вашего кошелька!
				</p>

				<p class="TextH"><b>Возникли проблемы? Юридические услуги от опытных юристов помогут вам!</b></p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Специфика юриспруденции и права требует высокого уровня подготовки, умения держать удар и находить «лазейки» в законодательстве, разумеется, только в рамках правового поля. Справиться со своими проблемами, особенно, если они касаются юридической направленности, могут далеко не все. Конечно, в суде можно представлять свои интересы самостоятельно, что позволит вам сэкономить деньги. Составить и заключить договор по стандартному шаблону тоже проще простого. Однако задумывались ли вы про возможные последствия таких действий? Неподготовленный и неопытный человек может допустить уйму глупых ошибок, которые негативным образом отразятся на исходе дела. В конечном счете предварительная экономия оборачивается дополнительными затратами и ненужной нервотрепкой. Доверьтесь профессионалам своего дела, и наши опытные специалисты квалифицированно помогут вам решить все проблемы и возникшие вопросы. Мы предоставляем все юридические услуги от консультации до полного юридического сопровождения, качество которых подтверждено богатейшим опытом.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
